import { Component, ReactNode } from 'react';
// import 'http://localhost/pixie.umd.js';
// import {EditorMode} from '../../config/editor-mode';
// import {EditorTheme} from '../../config/editor-theme';

class Editor extends Component{

  componentDidMount(){
    let {Pixie} = window;
    if(window.pixie){
      return
    }
    
      window.pixie = new Pixie({
        selector: "#editor-container",
        baseUrl: 'pixie-assets',
        crossOrigin: true,
        // image: "assets/images/samples/sample1.jpg",
        ui: {
          activeTheme: 'dark',
        },
        onSave: async function(data) {
         console.log(data);
        },
        onLoad: () => {
          console.log('ready!');
        },
        objectControls: {
          global: {
            unlockAspectRatio: true,
          },
          shape: {
            hideTopLeft: true,
          },
        },
        tools: {
          zoom: {
            allowUserZoom: true,
          },
          crop: {
            defaultRatio: '16:9',
          },
        },
        ui: {
          mode: 'inline',//EditorMode.INLINE,
          // activeTheme: EditorTheme.LIGHT,
          showExportPanel: true,
          activeTheme: 'dark',
          // visible: false,
        },
      })
    
  }
  
  render() {
    let {Pixie} = window
    return (
      <>
        <div id="editor-container" className="border border-divider" style={{width: '100vw', height: '100vh', margin: 'auto'}}></div>
      </>
    )
  }
}
export default Editor;